import http from '../http'
import { Category } from '../types'
import { API_URL } from './urls'

export const CategoriesApi = {
  list: async function (): Promise<Category[]> {
    return await http.get(API_URL.categories)
  },

  detail: async function (id: number): Promise<Category> {
    return await http.get(`${API_URL.categories}${id}/`)
  },
}
