import { createContext, useContext, useState } from 'react'

export type ViewType = 'list' | 'grid'

export const defaultPageContext = {
  viewType: 'list' as ViewType,
  loading: false,
  setLoading: async () => {},
  setViewType: async () => {},
}

type PageContextType = {
  loading: boolean
  viewType: ViewType
  setLoading: (val: boolean) => void
  setViewType: (val: ViewType) => void
}

export const PageContext = createContext<PageContextType>(defaultPageContext)

export const usePage = () => useContext(PageContext)

export const PageProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [viewType, setViewType] = useState<ViewType>('list')

  return (
    <PageContext.Provider
      value={{ loading, setLoading, viewType, setViewType }}
    >
      {children}
    </PageContext.Provider>
  )
}
