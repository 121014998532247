import { Category, Section } from '../services/types'

const formatMaterialSectionItem = (
  items: Section[],
  assembly: Section,
  sub_element: Section,
  element: Section
) => {
  return items.map((it) => ({
    pk: it.pk,
    code: it.code,
    name: it.name,
    type: 'material_sections',
    sub_element: sub_element,
    element: element,
    assembly: assembly,
  }))
}

export const categoriesToMenuProps = (categories: Category[]) => {
  let menu: any = []
  categories &&
    categories.length > 0 &&
    categories.forEach((el, index) => {
      const elParams = {
        pk: el.pk,
        code: el.code,
        name: el.name,
      }
      menu.push({
        code: el.code,
        name: el.name,
        pk: el.pk,
        type: 'elements',
        subMenu: [],
      })
      el.sub_elements.length > 0 &&
        el.sub_elements.forEach((sub, subIndex) => {
          const subParams = {
            pk: sub.pk,
            code: sub.code,
            name: sub.name,
          }
          if (el.sub_elements.length > 1) {
            menu[index].subMenu.push({
              code: sub.code,
              name: sub.name,
              pk: sub.pk,
              element: elParams,
              type: 'sub_elements',
              subMenu: [],
            })

            sub.assemblies.length > 0 &&
              sub.assemblies.forEach((as, asIndex) => {
                let assembly: any = {
                  pk: as.pk,
                  code: as.code,
                  name: as.name,
                  type: 'assemblies',
                  element: elParams,
                  sub_element: subParams,
                  subMenu: [],
                }
                const asParams = {
                  pk: as.pk,
                  code: as.code,
                  name: as.name,
                }
                if (as.material_sections.length > 1) {
                  assembly.subMenu = formatMaterialSectionItem(
                    as.material_sections,
                    asParams,
                    subParams,
                    elParams
                  )
                }
                if (sub.assemblies.length > 1) {
                  menu[index].subMenu[subIndex].subMenu.push(assembly)
                } else {
                  menu[index].subMenu[subIndex] = assembly
                }
              })
          } else {
            if (sub.assemblies.length > 0) {
              menu[index].subMenu = sub.assemblies
              sub.assemblies.forEach((as, asIndex) => {
                menu[index].subMenu[asIndex]['element'] = elParams
                menu[index].subMenu[asIndex]['sub_element'] = subParams
                if (as.material_sections && as.material_sections.length > 0) {
                  const asParams = {
                    pk: as.pk,
                    code: as.code,
                    name: as.name,
                  }
                  menu[index].subMenu[asIndex]['type'] = 'assemblies'
                  menu[index].subMenu[asIndex]['subMenu'] = []
                  if (as.material_sections.length > 1) {
                    menu[index].subMenu[asIndex][
                      'subMenu'
                    ] = formatMaterialSectionItem(
                      as.material_sections,
                      asParams,
                      subParams,
                      elParams
                    )
                  }
                }
              })
            }
          }
        })
    })

  return menu
}
