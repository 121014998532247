import http from '../http'
import { User, UserRating, RegisterUser, } from '../types'
import { API_URL } from './urls'

export const UsersApi = {
  list: async function (): Promise<User[]> {
    return await http.get(API_URL.users)
  },
  validateToken:  async function (token: string): Promise<User> {
    return await http.get(`${API_URL.validate_token}?token=${token}`)
  },
  register: async function (registerUser: RegisterUser): Promise<RegisterUser> {
    return await http.post(API_URL.user_invite, registerUser)
  },
  detail: async function (username: string): Promise<User> {
    return await http.get(`${API_URL.users}${username}/`)
  },
  me: async function (): Promise<User> {
    return await http.get(`${API_URL.users}me/`)
  },
  update: async function (user: User): Promise<User> {
    return await http.patch(`${API_URL.users}${user.username}/`, user)
  },
  ratings: async function (params: any = {}): Promise<UserRating[]> {
    return await http.get(API_URL.userRatings, { params: params })
  },
  createRating: async function (userRating: UserRating): Promise<UserRating> {
    return await http.post(API_URL.userRatings, userRating)
  },
  updateRating: async function (userRating: UserRating): Promise<UserRating> {
    return await http.patch(
      `${API_URL.userRatings}${userRating.id}/`,
      userRating
    )
  },
  deleteRating: async function (id: number): Promise<UserRating> {
    return await http.delete(`${API_URL.userRatings}${id}/`)
  },
  compareCarbonData: async function (ids: string): Promise<any> {
    return await http.get(API_URL.compareCarbonData, {
      params: {
        ids: ids,
      },
    })
  },
}
