import type { AppContext, AppProps } from 'next/app'
import App from 'next/app'
import Head from 'next/head'
import { useEffect, useState } from 'react'
import { init } from '../sentry'
import { MenuContext } from '../src/contexts/MenuContext'
import { UserContext } from '../src/contexts/UserContext'
import { CategoriesApi } from '../src/services/api/categories'
import { UsersApi } from '../src/services/api/users'
import { getCookie } from '../src/services/cookies'
import { Category, MenuItem, User } from '../src/services/types'
import { categoriesToMenuProps } from '../src/utils/menuUtils'
import { initGA, ANALYTICS_TOKEN, logPageView } from '../src/services/analytics'
import {
  addAuthorizationHeader,
  removeAuthorizationHeader,
} from '../src/utils/token'
import '../styles/globals.less'
import Router from 'next/router'
import NProgress from 'nprogress'
import { PageProvider } from '../src/contexts/PageContext'
import { QuestionsProvider } from '../src/contexts/QuestionContext'

init()

export interface NewAppProps extends AppProps {
  userData: User
  token: string
  categoriesData: Category[]
}

Router.events.on('routeChangeStart', () => NProgress.start())
Router.events.on('routeChangeComplete', () => NProgress.done())
Router.events.on('routeChangeError', () => NProgress.done())

function MyApp({
  Component,
  pageProps,
  userData,
  categoriesData,
  token,
}: NewAppProps) {
  const [user, setUser] = useState<User | null>(null)
  const [menu, setMenu] = useState<MenuItem[] | null>(null)
  const [hasAnalytics, setAnalytics] = useState<boolean>(false)

  useEffect(() => {
    token && addAuthorizationHeader(token)
  }, [token])

  useEffect(() => {
    setUser({ ...userData })
  }, [userData])

  useEffect(() => {
    const menuData =
      (categoriesData && categoriesToMenuProps(categoriesData)) || []
    menuData && setMenu([...menuData])
  }, [categoriesData])

  // set the page name
  const pageName = pageProps?.filename || pageProps?.data?.name || ''

  // google analytics
  useEffect(() => {
    if (ANALYTICS_TOKEN && !hasAnalytics) {
      initGA(ANALYTICS_TOKEN)
      setAnalytics(true)
      logPageView(pageName)
    } else {
      logPageView(pageName)
    }
  }, [ANALYTICS_TOKEN, pageProps])

  return (
    <>
      <Head>
        <link
          rel='apple-touch-icon'
          sizes='180x180'
          href='/favicon/apple-touch-icon.png'
        />
        <link
          rel='icon'
          type='image/png'
          sizes='32x32'
          href='/favicon/favicon-32x32.png'
        />
        <link
          rel='icon'
          type='image/png'
          sizes='16x16'
          href='/favicon/favicon-16x16.png'
        />
        <link rel='manifest' href='/favicon/site.webmanifest' />
        <link
          rel='mask-icon'
          href='/favicon/safari-pinned-tab.svg'
          color='#283a46'
        />
        <meta name='msapplication-TileColor' content='#283a46' />
        <meta name='theme-color' content='#283a46' />
      </Head>
      <UserContext.Provider value={{ user, setUser }}>
        <MenuContext.Provider value={{ menu, setMenu }}>
          <PageProvider>
            <QuestionsProvider>
              <Component {...pageProps} />
            </QuestionsProvider>
          </PageProvider>
        </MenuContext.Provider>
      </UserContext.Provider>
    </>
  )
}

MyApp.getInitialProps = async (appContext: AppContext) => {
  const { ctx } = appContext
  const { res, pathname } = ctx
  const token = getCookie('token', ctx)

  const redirectPage = (path: string) => {
    if (!pathname.includes(path)) {
      if (res) {
        res.writeHead(301, { Location: path })
        res.end()
      } else {
        appContext.router.push(path)
      }
    }
  }

  if (token) {
    addAuthorizationHeader(token)
    let appProps, userData, categoriesData
    try {
      const [appPropsRes, userDataRes] = await Promise.all([
        App.getInitialProps(appContext),
        UsersApi.me(),
      ])
      appProps = appPropsRes
      userData = userDataRes
    } catch (e: any) {
      if (e && e.statusCode === 401) {
        removeAuthorizationHeader(ctx)
        redirectPage('/login')
      }
      return {}
    }
    if (ctx.pathname.indexOf('/projects') === -1) {
      try {
        const categoriesDataRes = await CategoriesApi.list()
        categoriesData = categoriesDataRes
      } catch (e) {}
    }
    return { ...appProps, userData, categoriesData, token }
  } else {
    if (
      !pathname.includes('forgot-password') &&
      !pathname.includes('password-reset') &&
      !pathname.includes('term-and-conditions') &&
      !pathname.includes('privacy-policy') &&
      !pathname.includes('signup') &&
      !pathname.includes('login') &&
      !pathname.includes('favicon')

    ) {
      redirectPage('/login')
    }
    const appProps = await App.getInitialProps(appContext)
    return { ...appProps }
  }
}

export default MyApp
